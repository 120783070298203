import axios from "axios";
import {
  ProductT,
  ProductTypeT,
  ErrorResponse,
  HistoryResponseT,
  DecodedVinResponse,
  StatisticsResponse,
  MarketPriceItemT,
  ProductInfoT,
  CompanyUsersResponseT,
  CompanyHistoryResponseT,
} from "./types";
// import { useError } from "../context/error";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  withXSRFToken: true,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
});

const setAuthHeader = (authCode: string) => {
  axiosInstance.defaults.headers.common["X-Auth-Code"] = authCode;
};

/**
 * Get the CSRF cookie
 * @returns {Promise<AxiosResponse<any>>}
 * @see https://laravel.com/docs/8.x/csrf#csrf-x-csrf-token
 */
const getCSRFCookie = async () =>
  await axiosInstance.get("/sanctum/csrf-cookie");

/**
 * Get the current user
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
const getUser = async () => {
  const response = await axiosInstance.get("/api/user");
  return response.data;
};

/**
 * Update the current user profile
 * @param payload
 * @returns {Promise<AxiosResponse<any>>}
 */
const updateUserProfile = async (payload: {
  firstName: string;
  lastName: string;
  password?: string;
  confirmPassword?: string;
  phone?: string;
}) => {
  const response = await axiosInstance.patch("/api/user", {
    first_name: payload.firstName,
    last_name: payload.lastName,
    password: payload.password || null,
    password_confirmation: payload.confirmPassword || null,
    phone: payload.phone || null,
  });

  return response.data;
};

/**
 * Attempt to authenticate a user
 *
 * @param email
 * @param password
 * @param rememberMe
 * @returns {Promise<AxiosResponse<any>>}
 */
const authenticateUser = async (
  email: string,
  password: string,
  rememberMe: boolean,
  vipClient?: "ozk" | "vig"
) => {
  const response = await axiosInstance.post("/api/login", {
    email: email,
    password: password,
    rememberMe,
    vip_client: vipClient,
  });
  return response.data;
};

/**
 * Attempt to authenticate a user with social
 *
 * @param provider
 * @param token
 * @returns {Promise<AxiosResponse<any>>}
 */
const authenticateUserWithSocial = async (
  provider: "google",
  token: string
) => {
  const response = await axiosInstance.post("/api/socials/login", {
    provider: provider,
    token: token,
  });
  return response.data;
};

/**
 * Create a new user account
 * @param firstName
 * @param lastName
 * @param email
 * @param password
 * @param passwordConfirmation
 * @returns {Promise<AxiosResponse<any>>}
 */
const createAccount = async (
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  passwordConfirmation: string,
  phone?: string
) => {
  const response = await axiosInstance.post("/api/register", {
    first_name: firstName,
    last_name: lastName,
    email: email,
    phone: phone,
    password: password,
    password_confirmation: passwordConfirmation,
  });
  return response.data;
};

/**
 * Logout the current user
 * @returns {Promise<AxiosResponse<any>>}
 */
const logoutUser = async () => await axiosInstance.post("/api/logout");

/**
 * Request filter options
 * @returns {Promise<AxiosResponse<any>>}
 */
const getFilterOptions = async (
  product: ProductTypeT,
  filter: string,
  criterion: { [key: string]: string | number } = {}
) => {
  const response = await axiosInstance.get(
    `/api/products/${product}/vehicles/${filter}/options`,
    {
      params: { ...criterion },
    }
  );
  return response.data;
};

/**
 * Request filter options
 * @returns {Promise<AxiosResponse<any>>}
 */
const getVehiclePrices = async (
  product: ProductTypeT,
  criterion: {
    [key: string]: string | number | Array<string> | Array<number>;
  } = {}
) => {
  const response = await axiosInstance.get(
    `/api/products/${product}/vehicles/prices`,
    {
      params: { ...criterion },
    }
  );
  return response.data;
};

/**
 * @param productName
 * @returns
 */
const getProduct = async (productName: string): Promise<ProductT> => {
  const response = await axiosInstance.get(`/api/products/${productName}`);
  return response.data;
};

/**
 * @param productName
 * @returns
 */
const getProductInfo = async (productName: string): Promise<ProductInfoT> => {
  const response = await axiosInstance.get(`/api/products/${productName}/info`);
  return response.data;
};

/**
 * Retrieves all products for the auth user
 * @param appName
 * @returns
 */
const getProducts = async () => {
  const response = await axiosInstance.get(`/api/products`);
  return response.data;
};
/**
 * Retrieves a single product
 * @param appName
 * @returns
 */
const getProductEdit = async (productName: string | number) => {
  const response = await axiosInstance.get(`/api/products/${productName}/edit`);
  return response.data;
};

/**
 * save the user product
 * @param appName
 * @returns
 */
const saveProductChanges = async (
  productName: string,
  payload: { fields: Array<string>; modules: any }
) => {
  const response = await axiosInstance.patch(
    `/api/products/${productName}`,
    payload
  );
  return response.data;
};

/**
return response.data;
  const response = await axiosInstance.get(`/api/products/configuration`);
};
 * Decodes a VIN
 * @param vin
 * @returns {Promise<AxiosResponse<any>>}
 */
const decodeVIN = async (
  product: ProductTypeT,
  vin: string,
  regNumber?: string,
  freeDecoder?: boolean
): Promise<DecodedVinResponse | ErrorResponse> => {
  let params: { [key: string]: unknown } = {};
  if (regNumber) params.reg_number = regNumber;

  const endpoint = freeDecoder ? "free-decode" : "decode";

  const response = await axiosInstance.get(
    `/api/products/${product}/vin/${endpoint}/${vin}`,
    {
      params,
    }
  );
  return response.data;
};

/**
 * Retrieves statistics about the database
 *
 * @returns {Promise<StatisticsResponse>}
 */
const getStatistics = async (): Promise<StatisticsResponse> => {
  const response = await axiosInstance.get(`/api/statistics`);
  return response.data;
};

/**
 * Retrieves user balance about the database
 *
 * @returns {Promise<{balance:number}>}
 */
const fetchUserBalance = async (): Promise<{ balance: number }> => {
  const response = await axiosInstance.get(`/api/user/balance`);
  return response.data;
};

/**
 * Opens a new window with the PDF generation
 *
 * @param pdfType
 * @param params
 * @returns
 */
const openPDFGeneration = (
  pdfType: ProductTypeT,
  params: { [key: string]: any }
) => {
  const urlParams = new URLSearchParams();
  Object.keys(params).forEach((key) => urlParams.append(key, params[key]));

  if ((window as any).pdfWindowName && !(window as any).pdfWindowName.closed) {
    (window as any).pdfWindowName.close();
  }

  const pdfWindow = window.open(
    `${
      process.env.REACT_APP_API_URL
    }/api/generate/pdf/${pdfType}?${urlParams.toString()}`,
    `AEI PDF - ${pdfType}`,
    "toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,width=900,height=1200"
  );
  if (!pdfWindow) return;
  (window as any).pdfWindowName = pdfWindow;
};

/**
 * Opens a new window with the PDF generation from archive
 *
 * @param id
 * @returns
 */
const openHistoryPDFGeneration = (id: string | number) => {
  if ((window as any).pdfWindowName && !(window as any).pdfWindowName.closed) {
    (window as any).pdfWindowName.close();
  }
  const pdfWindow = window.open(
    `${process.env.REACT_APP_API_URL}/api/history/${id}/generate-pdf`,
    `AEI PDF - History`,
    "toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,width=900,height=1200"
  );
  if (!pdfWindow) return;
  (window as any).pdfWindowName = pdfWindow;
};
/**
 * Opens a new window with the PDF generation from archive
 *
 * @param id
 * @returns
 */
const openCompanyHistoryPDFGeneration = (id: string | number) => {
  window.open(
    `${process.env.REACT_APP_API_URL}/api/company/history/${id}/generate-pdf`,
    "_blank",
    "toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,width=900,height=1200"
  );
};

/**
 * Sends the contact us form to the backend
 *
 * @param email
 *
 * @returns {Promise<{message: string}>}
 */
const sendContactUsForm = async (
  reason: string,
  message: string,
  contactPhone: string
) => {
  const response = await axiosInstance.post("/api/feedback", {
    reason_id: reason,
    message: message,
    contact_phone: contactPhone,
  });
  return response.data;
};

/**
 * Requests a reset link
 *
 * @param email
 *
 * @returns {Promise<{message: string}>}
 */
const requestResetLink = async (email: string) => {
  const response = await axiosInstance.post("/api/request-reset-link", {
    email: email,
  });
  return response.data;
};

/**
 * Requests a verification code
 *
 * @param email
 *
 * @returns {Promise<{message: string}>}
 */
const requestVerification = async () => {
  const response = await axiosInstance.post(
    "/api/account/request-verification"
  );
  return response.data;
};

/**
 * Verifies the account
 *
 * @param email
 *
 * @returns {Promise<{message: string}>}
 */
const verifyAccount = async (code: string) => {
  const response = await axiosInstance.get(`/api/account/verify/${code}`);
  return response.data;
};

/**
 * Resets the password
 *
 * @param {{email: string;password: string; password_confirmation: string;token: string;}} payload
 *
 * @returns {Promise<{message: string}>}
 */
const resetPassword = async (payload: {
  email: string;
  password: string;
  password_confirmation: string;
  token: string;
}) => {
  const response = await axiosInstance.post("/api/reset-password", payload);
  return response.data;
};

/**
 * Get the market price
 * @param priceId
 */
const getMarketPrice = async (
  product: ProductTypeT,
  priceId: string,
  vin: string | null = null
): Promise<MarketPriceItemT[]> => {
  const response = await axiosInstance.get(
    `/api/products/${product}/${priceId}/market-prices`,
    {
      params: { vin },
    }
  );
  return response.data;
};

/**
 * Get the evaluation data from the price id
 * @param priceId
 */
const getEvaluationData = async (
  product: ProductTypeT,
  priceId: string,
  criterion: { [key: string]: any } = {}
): Promise<any> => {
  if (criterion.first_registration_date) {
    criterion.first_registration_date.setHours(12);
  }
  const response = await axiosInstance.get(
    `/api/products/${product}/${priceId}/evaluation`,
    {
      params: { ...criterion },
    }
  );
  return response.data;
};

/**
 * Return the superstructures
 **/
const getResourceSuperstructures = async (): Promise<any> => {
  const response = await axiosInstance.get(`/api/resources/superstructures`);
  return response.data;
};
/**
 * Return the superstructures
 **/
const getResourceReg49VehicleTypes = async (): Promise<any> => {
  const response = await axiosInstance.get(`/api/resources/reg49/types`);
  return response.data;
};
/**
 * Return the superstructures
 **/
const getResourceReg49VehicleTypesCountries = async (
  typeId: string
): Promise<any> => {
  const response = await axiosInstance.get(
    `/api/resources/reg49/types/${typeId}/countries`
  );
  return response.data;
};

/**
 * Upload multiple files
 * @param files
 */
const uploadFiles = async (
  files: FileList | File[]
): Promise<{
  uploaded_files_ids: number[];
  failed_files: string[];
}> => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append("files[]", files[i]);
  }
  const response = await axiosInstance.post("/api/upload", formData, {
    headers: {
      ...(axiosInstance.defaults.headers as any),
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

/**
 *
 * @param files
 */
const aiVehicleDataExtractor = async (file: File): Promise<any> => {
  const formData = new FormData();
  formData.append("file", file);
  const response = await axiosInstance.post("/api/ai-services/vehicle-data", formData, {
    headers: {
      ...(axiosInstance.defaults.headers as any),
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

/**
 * Upload multiple files
 * @param files
 */
const uploadCompanyBanner = async (file: File): Promise<any> => {
  const formData = new FormData();
  formData.append("banner", file);

  const response = await axiosInstance.post(
    "/api/company/configurations/pdf/banner",
    formData,
    {
      headers: {
        ...(axiosInstance.defaults.headers as any),
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

/**
 * Saves the evaluation
 * @param product: ProductTypeT,
 * @param payload: any
 */
const saveEvaluation = async (
  product: ProductTypeT,
  payload: any, // TODO: add the correct type
  evalId?: number
) => {
  const response = await axiosInstance.post(
    `/api/products/${product}/evaluation${evalId ? "/" + evalId : ""}`,
    payload
  );

  return response.data;
};

/**
 * Saves the evaluation
 * @param product: ProductTypeT,
 * @param payload: any
 */
const saveTechData = async (
  payload: any, // TODO: add the correct type
  archiveId?: number
) => {
  const response = await axiosInstance.post(
    `/api/products/tech-data${archiveId ? "/" + archiveId : ""}`,
    payload
  );

  return response.data;
};

/**
 * Attach graph image
 * @param product: ProductTypeT,
 * @param payload: { image: string;}
 * @param archiveId: number
 * @returns
 */
const attachGraphImage = async (
  product: ProductTypeT,
  payload: {
    market: string;
    image: string;
  },
  archiveId: number
) => {
  const response = await axiosInstance.post(
    `/api/products/${product}/${archiveId}/attach-graph`,
    payload
  );

  return response.data;
};

/**
 * Charge for usage of a product
 * @param product: ProductTypeT
 */
const chargeProduct = async (product: ProductTypeT) => {
  const response = await axiosInstance.post(`/api/products/${product}/charge`);
  return response.data;
};

const getHistory = async (
  searchCriteria: { [key: string]: unknown },
  page?: number
): Promise<HistoryResponseT> => {
  const response = await axiosInstance.get(`/api/history`, {
    params: { ...searchCriteria, page },
  });
  return response.data;
};

/**
 *  Retrieve given history entry
 *
 * @param id
 * @returns
 */
const getEvalEntryForEdit = async (
  product: ProductTypeT,
  id: string | number
): Promise<any> => {
  const response = await axiosInstance.get(
    `/api/products/${product}/evaluation/${id}/edit`
  );
  return response.data;
};

/**
 * Retrieves all company history based on the search criteria and page
 * @param searchCriteria
 * @param page
 * @returns
 */
const getCompanyHistory = async (
  searchCriteria: { [key: string]: unknown },
  page?: number
): Promise<CompanyHistoryResponseT> => {
  const response = await axiosInstance.get(`/api/company/history`, {
    params: { ...searchCriteria, page },
  });
  return response.data;
};

/**
 * Retrieves all company users based on the search criteria and page
 *
 * @param searchCriteria
 * @param page
 * @returns
 */
const getCompanyUsers = async (
  searchCriteria: { [key: string]: unknown },
  page?: number
): Promise<CompanyUsersResponseT> => {
  const response = await axiosInstance.get(`/api/company/users`, {
    params: { ...searchCriteria, page },
  });
  return response.data;
};

const getCompanyData = async (): Promise<any> => {
  const response = await axiosInstance.get(`/api/company`);
  return response.data;
};

/**
 * Retrieves a company user based on the user id
 *
 * @param userId
 * @returns
 *
 * @TODO: add the correct type
 */
const getCompanyUser = async (userId: number | string): Promise<any> => {
  const response = await axiosInstance.get(`/api/company/users/${userId}`);
  return response.data;
};

/**
 * Creates a new company user with the given payload.
 * @param payload - The data needed to create the new user.
 * @param payload.first_name - The first name of the user.
 * @param payload.last_name - The last name of the user.
 * @param payload.email - The email address of the user.
 * @param payload.phone - The phone number of the user (optional).
 * @param payload.role_id - The ID of the user's role.
 * @param payload.branch_id - The ID of the user's branch.
 * @param payload.office_id - The ID of the user's office.
 * @param payload.password - The password for the new user.
 * @returns A Promise that resolves with the new user data.
 */
const createCompanyUser = async (payload: {
  first_name: string;
  last_name: string;
  email: string;
  phone?: string;
  role_id: number;
  branch_id: number;
  office_id: number;
  password: string;
}): Promise<{ success: string }> => {
  const response = await axiosInstance.post(`/api/company/users`, payload);
  return response.data;
};

/**
 * Updates a company user with the provided payload.
 * @param payload - The data to update the user with.
 * @returns A Promise that resolves to the updated user data.
 */
const updateCompanyUser = async (
  userId: string,
  payload: {
    first_name: string;
    last_name: string;
    email: string;
    phone?: string;
    role_id: number;
    branch_id: number;
    office_id: number;
    password: string;
    is_active: boolean;
  }
): Promise<{ success: string }> => {
  const response = await axiosInstance.patch(
    `/api/company/users/${userId}`,
    payload
  );
  return response.data;
};

/**
 * Retrieves the list of branches for a company.
 * @returns A Promise that resolves to the list of branches.
 */
const getCompanyBranches = async (): Promise<any> => {
  const response = await axiosInstance.get(`/api/company/branches`);
  return response.data;
};

/**
 * Retrieves the company offices for a given branch ID.
 * @param branchId - The ID of the branch to retrieve offices for.
 * @returns A Promise that resolves to the response data.
 */
const getCompanyOfficesForBranch = async (
  branchId: number | string
): Promise<any> => {
  const response = await axiosInstance.get(
    `/api/company/branches/${branchId}/offices`
  );
  return response.data;
};

/**
 * Retrieve a list of Company user roles
 * @returns {Promise<AxiosResponse<any>>}
 */
const getCompanyRoles = async () => {
  const response = await axiosInstance.get(`/api/company/users/roles`);
  return response.data;
};

/**
 * Retrieves temp hash for the capturing event
 * @returns {Promise<AxiosResponse<any>>}
 */
const getTempHash = async () => {
  const response = await axiosInstance.get(`/api/generate/hash`);
  return response.data;
};

export {
  getUser,
  decodeVIN,
  getProduct,
  logoutUser,
  getHistory,
  getTempHash,
  uploadFiles,
  getStatistics,
  getCSRFCookie,
  createAccount,
  resetPassword,
  saveTechData,
  verifyAccount,
  setAuthHeader,
  chargeProduct,
  axiosInstance,
  getProductInfo,
  saveEvaluation,
  getMarketPrice,
  getCompanyUser,
  getCompanyData,
  getCompanyUsers,
  getProducts,
  saveProductChanges,
  getCompanyRoles,
  authenticateUser,
  getVehiclePrices,
  requestResetLink,
  attachGraphImage,
  getFilterOptions,
  fetchUserBalance,
  createCompanyUser,
  getCompanyHistory,
  updateCompanyUser,
  updateUserProfile,
  openPDFGeneration,
  sendContactUsForm,
  getEvaluationData,
  getProductEdit,
  uploadCompanyBanner,
  requestVerification,
  getCompanyBranches,
  getEvalEntryForEdit,
  aiVehicleDataExtractor,
  openHistoryPDFGeneration,
  getCompanyOfficesForBranch,
  authenticateUserWithSocial,
  getResourceSuperstructures,
  getResourceReg49VehicleTypes,
  openCompanyHistoryPDFGeneration,
  getResourceReg49VehicleTypesCountries,
};
